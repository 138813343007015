import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import useApiAxios from "../../api/useApiAxios";
import { Link, useLocation, useParams } from "react-router-dom";

const EditVendor = () => {
  let { id } = useParams();

  const {
    updateVendorApi,
    getAllCategoryApi,
    getAllSubCategoryApi,
    getVendorByIdApi,
  } = useApiAxios();

  const [subCategories, setSubCategories] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [vendor, setVendor] = React.useState();

  const initialValues = {
    businessName: vendor ? vendor.businessName : "",
    email: vendor ? vendor.email : "",
    _id: vendor ? vendor._id : "",
    category: vendor ? vendor.category : "",
    subCategory: vendor ? vendor.subCategory : "",
  };

  const validationSchema = Yup.object().shape({
    businessName: Yup.string()
      .matches(
        /^[A-Za-z][A-Za-z ]*$/,
        "Name must start with an alphabet and can contain spaces only between alphabets"
      )
      .required("Name is required")
      .max(25, "Name must be at most 25 characters"),
    category: Yup.string().required("Category is required"),
    subCategory: Yup.string().required("Subcategory is required"),
  });

  const onSubmit = async (values, { setSubmitting }) => {
    console.log(values);
    try {
      const updatedValues = { ...values, email: vendor.email }; // Ensure email stays unchanged
      await updateVendorApi(vendor._id, updatedValues);
    } catch (error) {
      console.error("Error updating vendor:", error);
    }
    setSubmitting(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const fetchData = async () => {
    try {
      const categoriesData = await getAllCategoryApi();
      const vendorData = await getVendorByIdApi(id);
      setVendor(vendorData.data.data);
      formik.setValues({
        businessName: vendorData.data.data
          ? vendorData.data.data.businessName
          : "",
        email: vendorData.data.data ? vendorData.data.data.email : "",
        _id: vendorData.data.data ? vendorData.data.data._id : "",
        category: vendorData.data.data ? vendorData.data.data.category[0] : "",
        subCategory: vendorData.data.data
          ? vendorData.data.data.subCategory[0]
          : "",
      });
      setCategories(categoriesData.data.data); // Assuming this returns an array
      const subCategoriesData = await getAllSubCategoryApi();
      setSubCategories(subCategoriesData.data.data); // Assuming this returns an array
    } catch (error) {
      console.error("Error fetching categories or subcategories:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []); // <-- Make sure the dependency array is empty
  console.log(formik.errors);
  return (
    <>
      <div className="panel-header">
        <div className="page-inner py-5">
          <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
            <div>
              <h2 className="pb-2 fw-bold">Edit Vendor</h2>
              <ul className="breadcrumbs">
                <li className="nav-home">
                  <Link to="/">
                    <i className="flaticon-home" />
                  </Link>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow" />
                </li>
                <li className="nav-item">
                  <a href="#">Vendor</a>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow" />
                </li>
                <li className="nav-item">
                  <a href="#">Edit Vendor</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="page-inner mt--5">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <div className="d-flex align-items-center">
                  <h4 className="card-title">Vendor Details</h4>
                </div>
              </div>
              <div className="card-body">
                <form onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="businessName">Business Name</label>
                        <input
                          type="text"
                          id="businessName"
                          name="businessName"
                          className={`form-control ${
                            formik.errors.businessName &&
                            formik.touched.businessName
                              ? "is-invalid"
                              : ""
                          }`}
                          value={formik.values.businessName}
                          onChange={formik.handleChange}
                        />
                        {formik.errors.businessName &&
                          formik.touched.businessName && (
                            <div className="invalid-feedback">
                              {formik.errors.businessName}
                            </div>
                          )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className={`form-control ${
                            formik.errors.email && formik.touched.email
                              ? "is-invalid"
                              : ""
                          }`}
                          value={formik.values.email}
                          disabled
                        />
                        {formik.errors.email && formik.touched.email && (
                          <div className="invalid-feedback">
                            {formik.errors.email}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="category">Category</label>
                        <select
                          id="category"
                          name="category"
                          className="form-control"
                          value={formik.values.category}
                          onChange={(e) => {
                            formik.handleChange(e);
                            console.log(e.target.value);
                          }}
                        >
                          {categories.map((category) => (
                            <option key={category._id} value={category._id}>
                              {category.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      {/* Subcategory Dropdown */}
                      <div className="form-group">
                        <label htmlFor="subCategory">Subcategory</label>
                        <select
                          id="subCategory"
                          name="subCategory"
                          value={formik.values.subCategory}
                          className="form-control"
                          onChange={formik.handleChange}
                        >
                          {Array.isArray(subCategories) &&
                            subCategories.map((subCategory) => (
                              <option
                                key={subCategory._id}
                                value={subCategory._id}
                              >
                                {subCategory.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditVendor;
