import React, { useContext, useEffect, useState } from "react";
import useApiAxios from "../../api/useApiAxios";
import { Context } from "../../context/Context";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ReactPaginate from "react-paginate"; // Import ReactPaginate

const InVoiceList = () => {
  const { getAllMarket, vendorInvoiceList, generateInvoice } = useApiAxios();
  const { userData } = useContext(Context);
  const [marketList, setMarketList] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedMarketId, setSelectedMarketId] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedVendors, setSelectedVendors] = useState(new Set());
  const [visibleDates, setVisibleDates] = useState(new Set());
  const [selectedDates, setSelectedDates] = useState({}); // State to track selected dates for each vendor

  const [currentPage, setCurrentPage] = useState(0);
  const [invoicePerPage, setInvoicePerPage] = useState(10);
  const invoicePerPageOptions = [5, 10, 20, 50];

  useEffect(() => {
    const fetchMarketList = async () => {
      try {
        const response = await getAllMarket();
        setMarketList(response.data.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchMarketList();
  }, []);

  useEffect(() => {
    if (selectedMarketId && startDate && endDate) {
      const fetchAttendanceData = async () => {
        try {
          const response = await vendorInvoiceList(selectedMarketId, {
            startDate,
            endDate,
          });
          setAttendanceData(response.data.data);
          setSelectedDates(
            response.data.data.reduce((acc, entry) => {
              acc[entry._id] = new Set();
              return acc;
            }, {})
          );
        } catch (err) {
          setError(err.message);
        }
      };

      fetchAttendanceData();
    }
  }, [selectedMarketId, startDate, endDate]);

  const handleMarketChange = (e) => {
    setSelectedMarketId(e.target.value);
    setStartDate(null);
    setEndDate(null);
    setAttendanceData([]);
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    // Close the date picker once the range is selected
    if (start && end) {
      setTimeout(() => document.activeElement.blur(), 0);
    }
  };

  const handleCheckboxChange = (vendorId) => {
    const updatedSelectedVendors = new Set(selectedVendors);

    // Toggle vendor selection
    if (updatedSelectedVendors.has(vendorId)) {
      updatedSelectedVendors.delete(vendorId);
    } else {
      updatedSelectedVendors.add(vendorId);
    }

    setSelectedVendors(updatedSelectedVendors);

    // Automatically toggle all dates for this vendor
    const vendorEntry = attendanceData.find((entry) => entry._id === vendorId);
    const updatedSelectedDates = { ...selectedDates };

    if (updatedSelectedVendors.has(vendorId)) {
      // Select all dates for the vendor
      updatedSelectedDates[vendorId] = new Set(
        vendorEntry.applyDate.map((applyDate) => applyDate._id)
      );
    } else {
      // Deselect all dates for the vendor
      delete updatedSelectedDates[vendorId];
    }

    setSelectedDates(updatedSelectedDates);
  };

  const handleDateCheckboxChange = (vendorId, dateId) => {
    const updatedSelectedDates = { ...selectedDates };
    const vendorDates = updatedSelectedDates[vendorId] || new Set();

    if (vendorDates.has(dateId)) {
      vendorDates.delete(dateId);
    } else {
      vendorDates.add(dateId);
    }

    updatedSelectedDates[vendorId] = vendorDates;
    setSelectedDates(updatedSelectedDates);

    // Check if all dates are unchecked
    if (vendorDates.size === 0) {
      const updatedSelectedVendors = new Set(selectedVendors);
      updatedSelectedVendors.delete(vendorId);
      setSelectedVendors(updatedSelectedVendors);
    }
  };

  const handleGenerateAllInvoices = async () => {
    const selectedData = attendanceData.filter((entry) =>
      selectedVendors.has(entry._id)
    );

    const invoiceData = selectedData.map((vendor) => {
      return {
        vendorId: vendor._id,
        price: vendor.price.unit_amount,
        dates: Array.from(selectedDates[vendor._id] || []),
      };
    });

    try {
      await generateInvoice({ data: invoiceData });
      alert("Invoices have been generated successfully.");

      setSelectedMarketId("");
      setStartDate("");
      setEndDate("");
    } catch (err) {
      setError(err.message);
      alert("Failed to generate invoices.");
    }
  };

  const toggleDatesVisibility = (vendorId) => {
    const updatedVisibleDates = new Set(visibleDates);

    if (updatedVisibleDates.has(vendorId)) {
      updatedVisibleDates.delete(vendorId);
    } else {
      updatedVisibleDates.add(vendorId);
    }

    setVisibleDates(updatedVisibleDates);
  };

  const indexOfLastVendor = (currentPage + 1) * invoicePerPage;
  const indexOfFirstVendor = indexOfLastVendor - invoicePerPage;
  const currentVendors = attendanceData.slice(
    indexOfFirstVendor,
    indexOfLastVendor
  );

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const handleVendorsPerPageChange = (event) => {
    setInvoicePerPage(Number(event.target.value));
    setCurrentPage(0); // Reset to the first page when changing the number of vendors per page
  };
  const doSomthistoWork = (price, item) => {
    const nextShapes = attendanceData.map((shape) => {
      if (shape._id === item._id) {
        // No change
        shape.price.unit_amount = price;
        return shape;
      } else {
        // Return a new circle 50px below
        return shape;
      }
    });
    // Re-render with the new array
    setAttendanceData(nextShapes);
    // setAttendanceData((pre))
  };

  return (
    <div>
      <style>{`
        .market-select {
          padding: 10px;
          margin: 5px;
          border-radius: 5px;
          border: 1px solid #ccc;
          font-size: 16px;
        }

        .table {
          width: 100%;
          margin: 20px 0;
          border-collapse: collapse;
        }

        .table th, .table td {
          padding: 10px;
          text-align: left;
          border: 1px solid #ddd;
        }

        .table th {
          background-color: #f2f2f2;
        }

        .action-buttons button {
          margin-right: 5px;
          padding: 5px 10px;
          border: none;
          border-radius: 3px;
          cursor: pointer;
        }

        .button-present {
          background-color: #28a745;
          color: white;
        }

        .button-absent {
          background-color: #dc3545;
          color: white;
        }

        .message-box {
          padding: 20px;
          margin: 20px 0;
          border: 1px solid #ddd;
          background-color: #f9f9f9;
          text-align: center;
          font-size: 16px;
          border-radius: 5px;
        }

        .dates-accordion {
          margin: 10px 0;
          padding: 10px;
          border: 1px solid #ddd;
          background-color: #f2f2f2;
          border-radius: 5px;
        }
      `}</style>

      <div className="panel-header">
        <div className="page-inner py-5">
          <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
            <div>
              <h2 className="pb-2 fw-bold">Invoice List</h2>
              <ul className="breadcrumbs">
                <li className="nav-home">
                  <Link to="/">
                    <i className="flaticon-home" />
                  </Link>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow" />
                </li>
                <li className="nav-item">
                  <a href="#">Invoice List</a>
                </li>
              </ul>
            </div>
            <div className="ml-md-auto py-2 py-md-0">
              <select
                onChange={handleMarketChange}
                value={selectedMarketId || ""}
                className="market-select"
              >
                <option value="" disabled>
                  Select a market
                </option>
                {marketList.map((market) => (
                  <option key={market._id} value={market._id}>
                    {market.name}
                  </option>
                ))}
              </select>

              {selectedMarketId && (
                <div className="ml-md-auto py-2 py-md-0">
                  <DatePicker
                    selected={startDate}
                    onChange={handleDateChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    inline={false} // Change to false if you want it as a pop-up
                    placeholderText="Select a date range"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {attendanceData.length > 0 ? (
        <div>
          <button
            className="market-select"
            style={{ float: "right", marginLeft: "auto" }}
            onClick={() => handleGenerateAllInvoices()}
          >
            {" "}
            Send Invoice
          </button>

          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Select</th>
                  <th>Vendor Name</th>
                  <th>Category</th>
                  <th>Price</th>
                  <th>Contact Number</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {attendanceData.map((entry) => (
                  <React.Fragment key={entry._id}>
                    <tr>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedVendors.has(entry._id)}
                          onChange={() => handleCheckboxChange(entry._id)}
                        />
                      </td>
                      <td>{entry.businessName}</td>
                      <td>{entry.categories.name}</td>
                      <td>
                        {" "}
                        <input
                          type="number"
                          value={entry.price.unit_amount}
                          onChange={(e) =>
                            doSomthistoWork(e.target.value, entry)
                          }
                        />
                      </td>

                      <td>{entry.contactNumber}</td>
                      <td>
                        <button
                          className="market-select"
                          onClick={() => toggleDatesVisibility(entry._id)}
                        >
                          {visibleDates.has(entry._id)
                            ? "Hide Dates"
                            : "View Dates"}
                        </button>
                      </td>
                    </tr>
                    {visibleDates.has(entry._id) && (
                      <tr>
                        <td colSpan="6">
                          <div className="dates-accordion">
                            <h4>Dates:</h4>
                            <ul>
                              {entry.applyDate.map((applyDate) => (
                                <li key={applyDate._id}>
                                  <input
                                    type="checkbox"
                                    checked={
                                      selectedDates[entry._id]?.has(
                                        applyDate._id
                                      ) || false
                                    }
                                    onChange={() =>
                                      handleDateCheckboxChange(
                                        entry._id,
                                        applyDate._id
                                      )
                                    }
                                  />
                                  {new Date(
                                    applyDate.dateId.date
                                  ).toLocaleDateString()}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>

            <div className="d-flex justify-content-between align-items-center pagination-container">
              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"..."}
                pageCount={Math.ceil(
                  attendanceData.length / invoicePerPageOptions
                )}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
              <div className="form-group d-flex align-items-center items-per-page">
                <label htmlFor="vendorsPerPage">Vendors per page: </label>

                <select
                  value={invoicePerPage}
                  onChange={handleVendorsPerPageChange}
                  className="form-control m-2"
                  style={{ width: "auto", display: "inline-block" }}
                >
                  {invoicePerPageOptions.map((option) => (
                    <option key={option} value={option}>
                      Show {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          {/* Uncomment this section if you want to add an "Action" button to generate all invoices */}
          {/* <div className="action-buttons">
            <button className="button-present" onClick={handleGenerateAllInvoices}>
              Generate All Invoices
            </button>
            <button className="button-absent" onClick={handleAction}>
              View Selected Data
            </button>
          </div> */}
        </div>
      ) : (
        <div className="message-box">
          {selectedMarketId && startDate && endDate
            ? "No Vendor data available for the selected date range."
            : "Please select a market and date range to view attendance data."}
        </div>
      )}
    </div>
  );
};

export default InVoiceList;
