import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../context/Context";
import useApiAxios from "../../api/useApiAxios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";

const VendorProfile = () => {
  const { updateVendorProfileApi } = useApiAxios();
  const [showPassword, setShowPassword] = useState(false);

  const { userData } = useContext(Context);

  const [userInfo, setUserInfo] = useState({
    businessName: userData.vendor.businessName,
    email: userData.vendor.email,
    contactPerson: userData.vendor.contactPerson,
    addProductMessage: userData.vendor.addProductMessage,
    email: userData.email,
    businessDescription: userData.vendor.businessDescription,
    password: "",
  });

  useEffect(() => {
    if (userData) {
      setUserInfo({
        businessName: userData.vendor.businessName,
        email: userData.email,
        contactPerson: userData.contactPerson,
        businessDescription: userData.businessDescription,
        addProductMessage: userData.addProductMessage,
      });
    }
  }, [userData]);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      const response = await updateVendorProfileApi(
        userData.vendor._id,
        values
      );
    } catch (error) {
      console.error("Error updating profile:", error);
      alert("Failed to update profile. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
      <div className="panel-header">
        <div className="page-inner py-5">
          <div className="d-flex align-items-left align-items-md-center                                                                                             flex-column flex-md-row">
            <div>
              <h2 className="pb-2 fw-bold">My Profile</h2>
              <ul className="breadcrumbs">
                <li className="nav-home">
                  <Link to="/">
                    <i className="flaticon-home" />
                  </Link>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow" />
                </li>
                <li className="nav-item">
                  <a href="#">My Account</a>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow" />
                </li>
                <li className="nav-item">
                  <a href="#">My Profile</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="page-inner mt--5">
        <div className="col-md-8 col-xl-9">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title mb-0">Profile</h5>
            </div>
            <div className="card-body">
              <Formik
                initialValues={userInfo}
                onSubmit={handleSubmit}
                validationSchema={Yup.object({
                  businessName: Yup.string(),
                  addProductMessage: Yup.string(),
                  about: Yup.string(),
                  phone: Yup.string()
                    .matches(
                      /^[0-9]+$/,
                      "Phone number must contain only numeric digits"
                    )
                    .min(10, "Phone number must be exactly 10 digits")
                    .max(10, "Phone number must be exactly 10 digits"),
                  password: Yup.string().min(
                    6,
                    "Password must be at least 6 characters"
                  ),
                })}
              >
                <Form>
                  <div className="row">
                    <div className="col-md-8">
                      <div className="form-group">
                        <label htmlFor="inputUsername">Business Name</label>
                        <Field
                          type="text"
                          className="form-control"
                          id="businessName"
                          name="businessName"
                          placeholder="businessName"
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputUsername">Email</label>
                        <Field
                          type="email"
                          className="form-control"
                          id="email"
                          name="email"
                          placeholder="Email"
                          readOnly
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputUsername">Product Message</label>
                        <Field
                          type="text"
                          className="form-control"
                          id="addProductMessage"
                          name="addProductMessage"
                          placeholder="addProductMessage"
                        />
                        <ErrorMessage
                          name="addProductMessage"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="about">Business Description</label>
                        <Field
                          as="textarea"
                          className="form-control"
                          id="businessDescription"
                          name="businessDescription"
                          placeholder="businessDescription"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="phone">Contact Person</label>
                        <Field
                          type="text"
                          className="form-control"
                          id="contactPerson"
                          name="contactPerson"
                          placeholder="Contact Person"
                        />
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Save changes
                  </button>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default VendorProfile;
